import React, { useState } from 'react';
import { User } from '../../data/models/user';
import { Alert, App, Button, Card, Form, Image, Input, Row } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { setUser } from '../../redux/userSlicer';
import { useDispatch } from 'react-redux';
import {  useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import srcImg from '../assets/images/blue-outline.svg'
import srcBcg from '../assets/images/login-background.png'

type loginProps = {
  onLogin: (userData: User) => void;
}

const Login: React.FC<loginProps> = ({ onLogin }) => {
  const isSmallScreen = window.innerWidth < 500;
  const { message } = App.useApp();
  const [notice, setNotice] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const onFinish = (values: any) => {
    if (!((values.username === 'admin' && values.password === '#ujamaa!2024') || 
      (values.username === 'business' && values.password === '123'))) {
      return setTimeout(() => {
        message.error('The combination of username and password is incorrect!', 5);
        //setNotice('The combination of username and password is incorrect!');
      }, 500);
    }
    const userData = { id: 'teste', profile_name: 'Roberto' } as User;
    
    Cookies.set('user', JSON.stringify(userData));

    dispatch(setUser(userData));
    
    message.success('User authenticated with success!');
    navigate('/');
  };

  return (
    <div style={{height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
    backgroundImage: `url(${srcBcg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
      <Card style={{ width: 350, height: 'fit-content', background: '#15013a',  border: 'none' }}>
        <Image preview={false} width={isSmallScreen? 300: 300} src={srcImg} />
          {notice && <Alert style={{ marginBottom: 24 }} message={notice} type="error" showIcon closable />}
          <Form onFinish={onFinish}>
            <Form.Item name="username" rules={[{ required: true, message: 'Please input your username!' }]}>
              <Input prefix={<UserOutlined />} placeholder="Username" />
            </Form.Item>
            <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
              <Input.Password prefix={<LockOutlined />} placeholder="Password" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ color: '#15013a', background: 'rgb(247 229 185)', width: '100%'}}>
                Login
              </Button>
            </Form.Item>
          </Form>
      </Card>
    </div>
  );
};;

export default Login;
