import { Select, SelectProps, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { makeRequest } from '../../../../helpers/axios-manipulator';
import { User } from '../../../../../data/models/user';


type Props = {
    setData: Function,
    data: any,
    className?: string,
    update: boolean,
    width?: string
}

const UserSelect = ({setData, data, className, update, width}: Props) => {
    const [options, setOptions] = useState<SelectProps['options']>()
    const [allUsers, setAllUsers] = useState<User[]>([])
    const [selected, setSelected] = useState<string | null>()
    useEffect(() => {
      const getUsers = async () => {
        try {
          const response = await makeRequest({
            method: 'get',
            url: `/api-users`,
            headers: { },
          });
          setAllUsers(response)
          let opts = [] as SelectProps['options'];
          await response.map((r:any, i:any) =>{
            return opts?.push(
              {
                label: r.username,
                value: i,
                emoji: r.profile_image_url,
                desc:  r.username,
              },
            )
          })
            setOptions(opts)
            if(selected){
              const value = await response.find((objeto:User) => objeto.username === selected);
              setData(value)
            }
          } catch (error) {
            console.error('Error fetching users:', error);
          }
        };
    
        getUsers();
      }, [update]);
      const handleChange = (value: any) => {
        const user = allUsers[parseInt(value)] as User
        setSelected(user.username)
        setData(allUsers[parseInt(value)])
      };
  return (
    <Select
    className={className}
    showSearch
    loading={options?.length === 0}
    style={{ width: width?width:'100%' }}
    placeholder="select one user"
    optionFilterProp='label'
    onChange={handleChange}
    optionLabelProp="label"
    options={options}
    optionRender={(option) => (
        <Space    >
          <span>{option.data.username}</span>
       <img  style={{ objectFit: 'cover'}} width={50} height={50}
                src={option.data.emoji} alt={option.data.username}
            />
        {option.data.desc}
      </Space>
    )}
  />
  )
}

export default UserSelect