import Icon from "@ant-design/icons/lib/components/Icon"
import { Menu } from "antd"
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom"
import { SystemConfigState, setTheme } from "../../../redux/systemConfigSlicer";
import { DesktopOutlined, FileImageOutlined, PieChartOutlined, QrcodeOutlined, UserOutlined } from "@ant-design/icons";
import React from "react";

export {};

const PrivateMenu = () => {
  const location = useLocation();
  const theme = useSelector((state: { systemConfig: SystemConfigState }) => state.systemConfig.theme);

  const menuItems: { [key: string]: '1' | '2' | '3' | '4' | '5' } = {
    '/': '1',
    '/home': '1',
    '/dashboard': '1',
    '/events': '2',
    '/users': '3',
    '/qrcode-generator': '4',
    '/placards': '5',
  };
  if (location.pathname.startsWith('/events/')) {
    menuItems[location.pathname] = '2';
  }
  const defaultSelectedKey = menuItems[location.pathname ?? '/'];
  return(
    <Menu theme={theme} defaultSelectedKeys={[defaultSelectedKey]} mode="inline">
      <Menu.Item key="1">
          <PieChartOutlined />
          <span>Dashboard</span>
          <Link to="/dashboard" />
      </Menu.Item>
      <Menu.Item key="2">
        <DesktopOutlined />
          <span>Events</span>
          <Link to="/events" />
      </Menu.Item>
      <Menu.Item key="3">
        <UserOutlined />
          <span>Users</span>
          <Link to="/users" />
      </Menu.Item>
      <Menu.Item key="4">
        <QrcodeOutlined />
          <span>QRcode generator</span>
          <Link to="/qrcode-generator" />
      </Menu.Item>
      <Menu.Item key="5">
      <FileImageOutlined />
          <span>Placards</span>
          <Link to="/placards" />
      </Menu.Item>
    </Menu>
  )
}

export default PrivateMenu;