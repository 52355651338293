import { useState } from 'react'
import { User } from '../../../data/models/user';
import UserSelect from '../../components/menu/Form/select/UserSelect';
import { App, Button, Form, Input, Typography } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { makeRequest } from '../../helpers/axios-manipulator';
import React from 'react';
const { Text } = Typography;

const Users = () => {
    const [data, setData] = useState<User>({} as User);
    const [loading, setLoading] = useState(false);
    const { message } = App.useApp();
    
    const save = async ()=>{
      if(data?.username?.length && data?.username?.length > 20)
      return message.error('username must have a maximum of 20 characters')
      setLoading(true)
      try {
        const response = await makeRequest({
          method: 'put',
          headers: {
            'Content-Type': 'application/json',
          },
          url: `/api-user/${data.id}`,
          data: {user:data},
        });
        setData(response);
        message.success('User updated with success!');
        setLoading(false)
      } catch (error) {
        message.error('something went wrong during the update')
        setLoading(false)
      }
    }
    
  return (
    <>
        <UserSelect update={loading} className='mb-5' setData={setData} data={data}/>
        <Text>Username</Text>
        <Input disabled={data && Object.keys(data).length === 0} className='mb-5' value={data?.username || ''}
            count={{
            show: true,
            max: 20,
          }}
          onChange={(e) => setData({...data, username:e.target.value})}
          placeholder="Username" />

        <Text>Display name</Text>
        <Input disabled={data && Object.keys(data).length === 0} className='mb-5'  value={data?.display_name || ''}
          onChange={(e) => setData({...data, display_name:e.target.value})}
          placeholder="Display name" />

        <Text>wallet address</Text>
        <Input disabled={data && Object.keys(data).length === 0} className='mb-5'  value={data?.wallet_address || ''}
          onChange={(e) => setData({...data, wallet_address:e.target.value})}
          placeholder="wallet address" />

        <Text>google id</Text>
        <Input disabled={data && Object.keys(data).length === 0} className='mb-5'  value={data?.google_id || ''}
          onChange={(e) => setData({...data, google_id:e.target.value})}
          placeholder="google id" />

        <Text>profile name</Text>
        <Input disabled={data && Object.keys(data).length === 0} className='mb-5'  value={data?.profile_name || ''}
          onChange={(e) => setData({...data, profile_name:e.target.value})}
          placeholder="profile name" />

        <Text>stripe id</Text>
        <Input disabled={data && Object.keys(data).length === 0} className='mb-5'  value={data?.stripe_id_acct || ''}
          onChange={(e) => setData({...data, stripe_id_acct:e.target.value})}
          placeholder="stripe id" />
        <Button
          disabled={data && Object.keys(data).length === 0}
          type="primary"
          icon={<SaveOutlined />}
          loading={loading}
          onClick={() => save()}
        >
          Save
        </Button>
    </>
  )
}

export default Users