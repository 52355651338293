import { Select, SelectProps, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { makeRequest } from '../../../../helpers/axios-manipulator';


type Props = {
    setData: Function,
    data: any,
}

const ArtworkSelect = ({setData, data}: Props) => {
    const [options, setOptions] = useState<SelectProps['options']>()
    useEffect(() => {
        const fetchBusinessEvents = async () => {
          try {
            const response = await makeRequest({
              method: 'get',
              url: `/api-artworks`,
              headers: { },
            });
            let opts = [] as SelectProps['options'];
            await response.map((r:any) =>{
               return opts?.push(
                    {
                        label: r.title,
                        value: r.id,
                        emoji: r.image_url,
                        desc:  r.title,
                      },
                )
            })
            setOptions(opts)
          } catch (error) {
            console.error('Error fetching business events:', error);
          }
        };
    
        fetchBusinessEvents();
      }, []);
      const handleChange = (value: string[]) => {
        setData({...data, artworks: value})
      };
  return (
    <Select
    mode="multiple"
    loading={options?.length === 0}
    style={{ width: '100%' }}
    placeholder="select one artwork"
    optionFilterProp='label'
    onChange={handleChange}
    optionLabelProp="label"
    options={options}
    optionRender={(option) => (
        <Space    >
          <span>{option.data.title}</span>
       <img  style={{ objectFit: 'cover'}} width={50} height={50}
                src={option.data.emoji} alt={option.data.title}
            />
        {option.data.desc}
      </Space>
    )}
  />
  )
}

export default ArtworkSelect