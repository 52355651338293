import axios, { AxiosRequestConfig } from "axios";

interface RequestParams {
    method: 'get' | 'post' | 'put' | 'delete';
    url: string;
    data?: any;
    headers?: Record<string, string>;
  }
  
  export async function makeRequest({ method, url, data, headers }: RequestParams): Promise<any> {
    const newUrl = (process.env.REACT_APP_ENV === 'test' ? 'http://localhost:3000':process.env.REACT_APP_DOMAIN) + url
    const config: AxiosRequestConfig = {
      method,
      url:newUrl,
      headers,
    };
  
    if (data) {
      if (method.toLowerCase() === 'get') {
        // For GET requests, use `params` instead of `data`
        config.params = data;
      } else {
        // For other methods, use `data`
        config.data = data;
      }
    }
  
    try {
      const response = await axios(config);
      return response.data;
    } catch (error) {
      // Handle error as needed
      console.error('Error making request:', error);
      throw error;
    }
  }