import React from 'react';
import ReactDOM from 'react-dom';
import App from './client/App';
import { Provider } from 'react-redux';
import store from './redux/appState';
import './client/App.css'
import 'antd-css-utilities/utility.min.css';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
